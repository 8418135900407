<template>
  <div>
    <b-card>
      <filters
        :detail-filter="getFilteredData"
        :search-data="searchData"
        :reset-filter="resetSearch"
      />
    </b-card>
    <div
      v-if="interviewLoading"
      class="text-primary"
    >
      <loading />
    </div>
    <b-card no-body>
      <counters />
      <b-table
        class="font-small-3"
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
        hover
      >
        <template #cell(interview_number)="data">
          <b-button
            variant="flat-primary"
            size="sm"
            :to="'/interviews/view/' + data.item.interview_number"
          >
            {{ data.item.interview_number }}
          </b-button>
        </template>
        <template #cell(customer)="data">
          {{ data.item.customer }}
          <div v-if="data.item.company_name">
            <small class="text-warning font-small-2">{{ data.item.company_name }}</small>
          </div>
        </template>
        <template #cell(status)="data">
          <div>
            <b-badge :variant="data.item.status === '1'? 'light-success' : 'light-danger'">
              <feather-icon :icon="data.item.status === '1'? 'CheckIcon':'XIcon'" />
              {{ data.item.status === '1'? 'Kapalı' : 'Açık' }}
            </b-badge>
          </div>
          <div>
            <small class="text-secondary font-small-2">{{ data.item.interview_status }}</small>
          </div>
          <div>
            <b-row>
              <b-col>
                <b-badge
                  v-if="data.item.interview_subject"
                  variant="light-info mr-1"
                >
                  <feather-icon icon="FileTextIcon" />
                  {{ data.item.interview_subject }}
                </b-badge>
                <b-badge
                  v-if="data.item.meet"
                  variant="light-primary"
                >
                  <feather-icon icon="UserCheckIcon" />
                  {{ data.item.meet }}
                </b-badge>
              </b-col>
            </b-row>
          </div>
        </template>
        <template #cell(idate)="data">
          <span class="font-weight-bolder">
            {{ data.item.username }}</span>
          <div>
            <span class="text-warning">{{ data.item.idate? moment(data.item.idate).format('DD.MM.YYYY') : '-' }}</span>
          </div>
          <div :class="data.item.alert_date? 'text-success' : 'text-muted'">
            <div class="font-small-2">
              Hatırlatma
            </div>
            <div class="font-small-2">
              {{ data.item.alert_date? moment(data.item.alert_date).format('DD.MM.YYYY') : '-' }}
            </div>
          </div>
        </template>
      </b-table>
      <b-card-footer>
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="dataCount"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
          <div class="text-muted font-small-2">
            Toplam {{ dataCount | toNumber }} adet görüşme bulundu.
          </div>
        </div>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BTable, BCardFooter, BPagination, BButton, BBadge, BCol, BRow,
} from 'bootstrap-vue'
import Loading from '@/layouts/components/Loading.vue'
import Filters from '@/views/Interviews/Lists/Filters/Insurance.vue'
import Counters from '@/views/Interviews/components/Counters.vue'

export default {
  name: 'Insurance',
  components: {
    BCard,
    BTable,
    BCardFooter,
    BPagination,
    BButton,
    BBadge,
    Loading,
    Filters,
    Counters,
    BCol,
    BRow,
  },
  props: {
    interviewStatus: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      currentPage: 1,
      perPage: 25,
      search: '',
      fields: [
        {
          key: 'interview_number',
          label: 'No',
          thStyle: { width: '100px', verticalAlign: 'middle' },
        },
        {
          key: 'customer',
          label: 'Müşteri',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'status',
          label: 'Durum',
          thStyle: { width: '350px', verticalAlign: 'middle' },
        },
        {
          key: 'idate',
          label: 'İlk Görüşme',
          thStyle: { width: '160px', verticalAlign: 'middle' },
        },
      ],
      dataQuery: {
        select: [
          'com_interview.interview_number AS interview_number',
          'com_interview.idate AS idate',
          'com_customer.name AS customer',
          'com_customer.company_name AS company_name',
          'com_interview.status AS status',
          'com_meet.title AS meet',
          'com_interview_subject.title AS interview_subject',
          'com_interview_status.title AS interview_status',
          'com_user.name AS username',
        ],
        where: {
          'com_interview.initial': 1,
          'com_interview.id_com_interview_type': 4,
          'com_interview.status': 0,
        },
        limit: 25,
        start: 0,
        alertDate: true,
      },
    }
  },
  computed: {
    interviewLoading() {
      return this.$store.getters['interviews/getInterviewLoading']
    },
    dataList() {
      return this.$store.getters['interviews/getInterviews']
    },
    dataCount() {
      return this.$store.getters['interviews/getInterviewsCount']
    },
    quickSearch() {
      return this.$store.getters['interviews/getQuickSearch']
    },
    filterData() {
      return this.$store.getters['interviews/getInterviewFilters']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    searchData() {
      if (this.quickSearch.keyword.length > 0) {
        this.dataQuery.or_like = {
          'com_interview.interview_number': this.quickSearch.keyword,
          'com_customer.name': this.quickSearch.keyword,
          'com_customer.company_name': this.quickSearch.keyword,
          'com_customer.phone': this.quickSearch.keyword,
        }
        this.getDataList()
      }
    },
    resetSearch() {
      this.dataQuery.or_like = {}
      this.dataQuery.where = {
        'com_interview.initial': 1,
        'com_interview.id_com_interview_type': 4,
        'com_interview.status': 0,
      }
      this.$store.dispatch('interviews/interviewFilterReset')
      this.quickSearch.keyword = null
      this.getDataList()
    },
    getFilteredData() {
      const where = {
        'com_interview.initial': 1,
        'com_interview.id_com_interview_type': 4,
      }
      if (this.filterData.id_com_interview_subject) {
        where['com_interview.id_com_interview_subject'] = this.filterData.id_com_interview_subject
      }
      if (this.filterData.id_com_meet) {
        where['com_interview.id_com_meet'] = this.filterData.id_com_meet
      }
      if (this.filterData.id_com_user) {
        where['com_interview.id_com_user'] = this.filterData.id_com_user
      }
      if (this.filterData.status !== null) {
        where['com_interview.status'] = this.filterData.status
      }
      if (this.filterData.sdate) {
        where['com_interview.idate >='] = this.filterData.sdate
      }
      if (this.filterData.edate) {
        where['com_interview.idate <='] = this.filterData.edate
      }
      this.dataQuery.where = where
      this.getDataList()
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      if (['7'].includes(this.userData.id_com_department)) {
        if (!this.dataQuery.where['com_interview.id_com_user']) {
          if (['5'].includes(this.userData.id_com_user_type)) {
            this.dataQuery.where['com_interview.id_com_user'] = this.userData.id
            this.filterData.id_com_user = this.userData.id
          }
        }
      }
      this.$store.dispatch('interviews/interviewsList', this.dataQuery)
    },
  },
}
</script>
